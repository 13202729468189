<template>
  <div id="hero" class="relative bg-cover bg-no-repeat bg-center min-h-screen md:h-screen w-full font-rouben transform  " :style="{'color': color }">
    <img loading="lazy" class="absolute w-full h-full object-cover" :src="image?.url" :srcset="getSrcSet({image})" alt="">
    <div class="absolute w-full h-full flex flex-col items-center z-30 ">
      <div class="w-full max-w-screen-2xl  h-auto flex-grow flex flex-col px-8 md:px-20 pt-24 md:mt-0 justify-center items-center md:items-start mt-12 flex-shrink-0 shrink-0">
        <h1 :ref="setRef" data-anim="0" class="opacity-0 md:leading-tight tracking-wide w-full md:w-7/12 font-extrabold md:font-bold text-5xl md:text-6xl text-center md:text-left break-words hyphen">{{titel}}</h1>
        <div class="flex flex-col md:flex-row w-full">
          <div :ref="setRef" data-anim="0" v-html="convertMarkdown(subtext)" class="md:w-1/2 md:pr-6 opacity-0 markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-center md:text-left" />
          <div v-if="rightText && rightText.length > 1" :ref="setRef" data-anim="0" v-html="convertMarkdown(rightText)" class="md:w-1/2 md:pl-6 opacity-0 markdown whitespace-pre-line text-base mb-3 leading-7 mt-3 lg:mt-0 text-center md:text-left" />
        </div>
        <div :ref="setRef" data-anim="0" class="opacity-0 w-full md:w-auto flex flex-col md:flex-row justify-around">
          <customButton v-if="scrollTo1 && label1" class="mr-2" :label=label1 mode="dark"  @click="scrollTo({ page: scrollTo1 })" />
          <customButton v-else-if="label1 && showButtonOne" class="mr-2" :label=label1 mode="dark"  @click="navigateTo()" />
          <customButton v-if="priceButton && showPriceButton" :label="text.prices" mode="dark" class="mr-2" @click="navigateTo({section: 'preise'})" />
          <customButton v-if="label2 && !navigate" :label=label2 mode="dark" @click="scrollTo({ page: scrollTo2 })" />
          <customButton v-if="label2 && navigate && showButtonTwo" :label=label2 mode="dark" class="mr-2" @click="navigateTo({ page: scrollTo2 })" />
        </div>
        <div :ref="setRef" data-anim="0" v-html="convertMarkdown(additionalText)" class="md:w-5/12 mt-2 opacity-0 markdown whitespace-pre-line text-base mb-3 mt-5 leading-7 text-center md:text-left" />
      </div>

      <!-- running line -->
      <div v-if="runningLine" class="w-full whitespace-nowrap font-rouben tracking-widest py-12 font-bold select-none flex-shrink-0" :style="{'color': runningLineColor}">
        <div class="text-4xl md:text-5xl uppercase mt-3 lg:mt-5 flex flex-row">
          <marquee-text :repeat="12" :key="runningLine">
            <div class="mr-12">{{ runningLine }}</div>
          </marquee-text>
        </div>
      </div>
    </div>
    <div class="absolute w-full h-full bg-black opacity-30"></div>
    <div class="w-full h-full flex-grow" ></div>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';

// Symbols
import customButton from './symbols/customButton.vue';

// Mixins
import intersectionObserver from '../mixins/intersectionObserver.vue';
import chooseImageFormat from '../mixins/chooseImageFormat.vue';
import convertMarkdown from '../mixins/convertMarkdown.vue';

export default {
  name: 'heroSection',
  props: ['titel', 'subtext', 'additionalText', 'image', 'label1', 'label2', 'scrollTo1', 'scrollTo2', 'navigate', 'showButtonOne', 'showButtonTwo', 'rightText', 'priceButton', 'showPriceButton', 'color', 'runningLine', 'runningLineColor'],
  components: { MarqueeText, customButton },
  mixins: [intersectionObserver, chooseImageFormat, convertMarkdown],

  computed: {
    currentLang() {
      return this.$store.state.lang.currentLang;
    },
    mobile() {
      return this.$store.state.data.mobile;
    },
    text() {
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
  },

  methods: {
    getSrcSet({ image }) {
      let srcset = '';
      if (image?.formats) {
        Object.keys(image?.formats)
          .forEach((k) => {
            srcset += `${image?.formats[k]?.url} ${image?.formats[k]?.width / 2.5}w,`;
          });
      }
      const cropped = srcset.slice(0, -1);
      return cropped;
    },

    scrollTo(id) {
      let threshold = 0;
      if (this.mobile) { threshold = 75; }
      const el = document.getElementById(id?.page);
      if (el) window.scrollTo({ top: el.offsetTop - threshold, left: 0, behavior: 'smooth' });
    },

    navigateTo({ page, section }) {
      if (page) this.$router.push({ name: page, params: { lang: this.currentLang } });
      else if (section) this.$router.push({ name: 'Pages', params: { lang: this.currentLang, slug: 'bouldern' }, hash: `#${section}` });
      else this.$router.push({ name: 'Pages', params: { lang: this.currentLang, slug: 'bouldern' } });
    },
  },
};
</script>
